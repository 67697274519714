

export default {
  namespaced: true,
  state: {
    user: {},
    
  },
  
  mutations: {
    SET_USER (state, val){
        console.log(val)
        state.user = val
    }
  },
  actions: {},
}
