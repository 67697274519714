import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import firebase from 'firebase/app'

import Toasted from 'vue-toasted';
import {BFormCheckbox} from 'bootstrap-vue'


Vue.component('b-form-checkbox', BFormCheckbox);

Vue.use(Toasted)


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false


var firebaseConfig = {
  apiKey: "AIzaSyD67GG2B_S22Dhu9r3dv-LxO3di2aEH8cw",
  authDomain: "jadescaps.firebaseapp.com",
  projectId: "jadescaps",
  storageBucket: "jadescaps.appspot.com",
  messagingSenderId: "1090467578217",
  appId: "1:1090467578217:web:705a3f60f4b84620393df8",
  measurementId: "G-0HMC3C7TJ5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);





new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
