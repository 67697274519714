export default {
    namespaced: true,
    state: {
      property_no: 0,
      
    },
    getters: {
      property_no :state => {
        return state.property_no
      }
    },
    
    mutations: {
      SET_CALENDAR (state, val){
          console.log(val)
          state.property_no = val;
      }
    },
    actions: {},
  }
  